import { type BaseData, request } from '@/utils';
import { Input, Modal, message } from 'antd';
import { observer } from 'mobx-react';
import { useState } from 'react';
import type { ModalProps } from './interface';

const CancelInterview = observer(({ visible, item, onCancel, cb }: ModalProps) => {
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    setReason(e.target.value);
  };
  const onSave = async () => {
    setLoading(true);
    try {
      const res = await request<BaseData<string>>({
        url: '/pbb/platform/sys/recruit/cancelInterview',
        method: 'POST',
        data: {
          recruitId: item.recruitId,
          reason,
        },
      });
      message.success(res.data);
      cb();
      onCancel();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      title="取消面试"
      centered={true}
      onCancel={() => {
        setReason('');
        onCancel();
      }}
      confirmLoading={loading}
      onOk={onSave}
    >
      取消原因：
      <Input.TextArea
        className="!h-[150px] mb-3"
        maxLength={50}
        showCount={true}
        value={reason}
        onChange={onChange}
      />
    </Modal>
  );
});

export default CancelInterview;
