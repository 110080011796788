import AnchorInfoModal from '@/compnents/anchorInfo';
import { SearchListStructure } from '@/utils';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import InterviewSchedule from './InterviewSchedule';
import AnchorEvaluate from './anchorEvaluate';
import ApplyPayment from './applyPayment';
import CancelInterview from './cancelInterview';
import ConfirmSignup from './confirmSignup';
import EmployerEvaluation from './employerEvaluate';
import History from './history';
import RefuseReason from './refuseReason';
import Store from './store';

const Employment = observer(() => {
  const store = useMemo(() => new Store(), []);
  const { mainStore } = store;
  return (
    <div>
      <SearchListStructure store={mainStore} />
      {/* 历史记录 */}
      <History
        visible={store.historyVisible}
        item={store.historyData}
        onCancel={() => {
          store.historyVisible = false;
        }}
      />
      {/* 拒绝邀约 */}
      <RefuseReason
        visible={store.refuseVisible}
        onCancel={() => {
          store.refuseVisible = false;
        }}
        item={store.refuseData}
        cb={store.refreshData}
      />
      {/* 报名确认 */}
      <ConfirmSignup
        visible={store.confirmVisible}
        onCancel={() => {
          store.confirmVisible = false;
        }}
        item={store.confirmData}
        cb={store.refreshData}
      />
      {/* 发起面试/修改面试日程 */}
      <InterviewSchedule
        type={store.interviewType}
        onCancel={() => {
          store.interviewVisible = false;
        }}
        item={store.interviewData}
        visible={store.interviewVisible}
        cb={store.refreshData}
      />
      {/* 取消面试 */}
      <CancelInterview
        visible={store.cancelVisible}
        onCancel={() => {
          store.cancelVisible = false;
        }}
        item={store.cancelData}
        cb={store.refreshData}
      />
      {/* 主播评价 */}
      <AnchorEvaluate
        visible={store.anchorEvaVisible}
        type={store.anchorEvaType}
        onCancel={() => {
          store.anchorEvaVisible = false;
        }}
        item={store.anchorEvaData}
        cb={store.refreshData}
      />
      {/* 雇主评价 */}
      <EmployerEvaluation
        visible={store.employerEvaVisible}
        type={store.employerEvaType}
        onCancel={() => {
          store.employerEvaVisible = false;
        }}
        item={store.employerEvaData}
        cb={store.refreshData}
      />
      {/* 发红包 */}
      <ApplyPayment
        visible={store.applyPaymentVisible}
        onCancel={() => {
          store.applyPaymentVisible = false;
        }}
        item={store.applyPaymentData}
        cb={store.refreshData}
      />
      <AnchorInfoModal store={store.anchorInfoModalStore} />
    </div>
  );
});
export default Employment;
