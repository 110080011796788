import { type BaseData, request } from '@/utils';
import { EnvironmentOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, InputNumber, Modal, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AmapModal, AmapModelStore } from './chooseAddress';
import type { ModalProps } from './interface';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const InterviewSchedule = ({ visible, onCancel, item, type, cb }: ModalProps) => {
  const amapModelStore = useMemo(() => new AmapModelStore(), []);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<{ address?: string; location: string }>();
  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disableTime = (date) => {
    const today = new Date();
    if (dayjs(date).format('YYYY-MM-DD') === dayjs(today).format('YYYY-MM-DD')) {
      const hour = dayjs(today).hour();
      const minute = dayjs(today).minute();
      console.log('hour', hour);
      const chooseHour = dayjs(date).hour();
      return {
        disabledHours: () => range(0, 24).splice(0, Number(hour)),
        disabledMinutes: () => (chooseHour > hour ? [] : range(0, 60).splice(0, Number(minute))),
      };
    }
    console.log('disableTime', date);
    return {};
  };
  const getInfo = async () => {
    const res = await request<BaseData<any>>({
      url: '/pbb/platform/sys/recruit/getInterviewInfo',
      method: 'POST',
      data: {
        id: item.recruitId,
      },
    });
    const { interviewTime, interviewAddressPoint, ...rest } = res.data;
    form.setFieldsValue({ ...rest, interviewTime: dayjs(interviewTime) });
    const location = `${interviewAddressPoint.longitude.toString()},${interviewAddressPoint.latitude.toString()}`;
    setAddress({ location: location });
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (visible && type === 'edit') {
      getInfo();
    }
  }, [visible]);

  const getAddress = (value: { address: string; location: string }) => {
    setAddress(value);
    form.setFieldsValue({ interviewAddress: value.address });
  };
  const onSave = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        console.log(values, 'values');
        if (new Date(values.interviewTime).getTime() < Date.now()) {
          message.error('面试时间必须大于当前时间');
          return;
        }
        const longitude = address.location.split(',')[0];
        const latitude = address.location.split(',')[1];

        const res = await request<BaseData<string>>({
          url: '/pbb/platform/sys/recruit/auditInterview',
          method: 'POST',
          data: {
            ...values,
            recruitId: item.recruitId,
            interviewTime: values.interviewTime.format('YYYY-MM-DD HH:mm:ss'),
            interviewAddressPoint: {
              latitude,
              longitude,
            },
          },
        });
        message.success('保存成功');
        cb();
        onCancel();
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal
      open={visible}
      title={type === 'new' ? '发起面试' : '修改面试日程'}
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={onSave}
      confirmLoading={loading}
      maskClosable={false}
      centered={true}
    >
      <Form
        form={form}
        {...layout}
      >
        <Form.Item label="JD名称">
          <div>{item?.positionName}</div>
        </Form.Item>
        <Form.Item label="主播姓名">
          <div>{item?.userName}</div>
        </Form.Item>
        <Form.Item
          label="联系电话"
          required={true}
          name="interviewContact"
          rules={[{ required: true, message: '请输入正确的联系电话', pattern: /^1[3456789]\d{9}$/ }]}
        >
          <Input
            autoComplete="off"
            placeholder="请输入手机号"
            maxLength={11}
          />
        </Form.Item>
        <Form.Item
          label="面试时间"
          required={true}
          name="interviewTime"
          rules={[{ required: true, message: '请选择面试时间' }]}
        >
          <DatePicker
            showTime={true}
            showHour={true}
            showMinute={true}
            className="w-full"
            minDate={dayjs()}
            disabledTime={disableTime}
          />
        </Form.Item>
        <Form.Item
          label="面试地址"
          required={true}
        >
          <div className="flex items-center gap-2">
            <Form.Item
              noStyle={true}
              name="interviewAddress"
              rules={[{ required: true, message: '请选择面试地址' }]}
              required={true}
            >
              <Input
                disabled={true}
                className="flex-1"
                placeholder="请选择完整地址(包含省市区)"
              />
            </Form.Item>
            <EnvironmentOutlined
              onClick={() => amapModelStore.onOpen(getAddress)}
              style={{ cursor: 'pointer', color: '#1890ff', fontSize: '24px' }}
            />
          </div>
        </Form.Item>
        <Form.Item
          label="楼层/门牌号"
          name="houseNumber"
        >
          <Input
            placeholder="请输入楼层/门牌号"
            maxLength={10}
          />
        </Form.Item>
        <Form.Item
          label="备注事项(雇主)"
          name="interviewMemoEmployer"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="备注事项(主播)"
          name="interviewMemo"
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
      <AmapModal store={amapModelStore} />
    </Modal>
  );
};

export default observer(InterviewSchedule);
