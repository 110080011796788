import { type BaseData, request } from '@/utils';
import { Input, Modal, Radio, message } from 'antd';
import { observer } from 'mobx-react';
import { useState } from 'react';
import type { ModalProps } from './interface';

const ConfirmSignup = observer(({ visible, onCancel, item, cb }: ModalProps) => {
  const [showInput, setShowInput] = useState(false);
  const [confirm, setConfirm] = useState<number>(1);
  const [reason, setReason] = useState<string>();
  const [loading, setLoading] = useState(false);
  const onConfirmChange = (e) => {
    setConfirm(e.target.value);

    if (e.target.value === 2) {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };

  const onChangeInput = (value) => {
    setReason(value);
  };

  const onSave = async () => {
    console.log('first', confirm, reason);
    try {
      setLoading(true);
      const res = await request<BaseData<string>>({
        url: '/pbb/platform/sys/recruit/brokerConfirmSignUp',
        method: 'POST',
        data: {
          isSignUp: confirm,
          reason: reason,
          recruitId: item.recruitId,
        },
      });
      message.success(res.data);
      cb();
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setConfirm(1);
    setReason('');
    setShowInput(false);
    onCancel();
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title="确认报名"
      width={500}
      centered={true}
      onOk={onSave}
      confirmLoading={loading}
      maskClosable={false}
    >
      <div className="flex">
        <div className="text-14 font-500">
          <span className="text-[red]">*</span>是否报名：
        </div>
        <Radio.Group
          value={confirm}
          onChange={onConfirmChange}
        >
          <Radio value={1}>报名</Radio>
          <Radio value={2}>不报名</Radio>
        </Radio.Group>
      </div>
      {showInput && (
        <div>
          <div className="text-14 font-500">
            <span className="text-[red]">*</span>拒绝原因：
          </div>
          <div className="mt-3 px-5">
            <div className="relative h-33 w-full">
              <Input.TextArea
                showCount={true}
                className="rounded-1 w-full px-3 !h-30 !bg-[#F7F7F7]"
                placeholder="请输入其他原因"
                value={reason}
                maxLength={50}
                onChange={(e) => onChangeInput(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
});
export default ConfirmSignup;
