import { type BaseData, request } from '@/utils';
import { Form, Input, InputNumber, Modal, message } from 'antd';
import { observer } from 'mobx-react';
import { useState } from 'react';
import type { ModalProps } from './interface';

const ApplyPayment = ({ visible, onCancel, item }: ModalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(values, 'values');
        setLoading(true);
        const res = await request<BaseData<string>>({
          url: '/pbb/platform/sys/recruit/applyReward',
          method: 'POST',
          data: {
            ...values,
            recruitId: item.recruitId,
            userId: item.userId,
          },
        });
        message.success(res.data);
        onClose();
      })
      .catch((errorInfo) => {
        console.log(errorInfo, 'errorInfo');
        setLoading(false);
      });
  };

  const onClose = () => {
    form.resetFields();
    onCancel();
  };
  return (
    <Modal
      open={visible}
      title={'申请打款'}
      centered={true}
      onCancel={onClose}
      maskClosable={false}
      onOk={onSave}
      confirmLoading={loading}
    >
      <Form form={form}>
        <Form.Item
          label={'打款金额'}
          name={'applyAmount'}
          required={true}
          rules={[{ required: true, message: '请输入打款金额' }]}
        >
          <InputNumber
            min={1}
            max={200}
          />
        </Form.Item>
        <Form.Item
          label={'申请原因'}
          name={'applyReason'}
          required={true}
          rules={[{ required: true, message: '请输入申请原因' }]}
        >
          <Input.TextArea
            placeholder={'请输入'}
            className="!h-30"
            maxLength={50}
            showCount={true}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(ApplyPayment);
