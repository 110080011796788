import { type BaseData, request } from '@/utils';
import { Form, Input, Modal, Radio, message } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import type { ModalProps } from './interface';

class Store {
  @observable public reasonList: { dictName: string; dictValue: string; checked?: boolean }[] = [];
  @observable public evaluate: string;
  @observable public loading: boolean;
  @observable public interviewed: string;
  @observable public isReal: string;
  @observable public showReasonList = false;
  @observable public item: any;
  @observable public cb: () => void;
  @observable public onCancel: () => void;

  @action public getList = async () => {
    if (this.reasonList.length) {
      return;
    }
    const res = await request<BaseData<{ dictName: string; dictValue: string }[]>>({
      url: '/pbb/platform/anon/system/sysDict/getListByType',
      method: 'POST',
      data: { dictType: 'anchor_interview_fail_feedback' },
    });
    const list = res.data.map((el) => {
      return { ...el, checked: false };
    });
    this.reasonList = list;
  };

  @action public getInfo = async () => {
    const res = await request<BaseData<any>>({
      url: '/pbb/platform/sys/recruit/getInterviewFeedbackByUser',
      method: 'POST',
      data: {
        id: this.item.recruitId,
      },
    });
    await this.getList();
    this.onChange(res.data.positionNotTrueReason || []);
    this.onValChange('interviewed', res.data.interviewed.toString());
    this.onValChange('isReal', res.data.positionIsTrue.toString());
    this.onValChange('evaluate', res.data.interviewEvaluate);
  };

  @action public onChange = (ids: string[]) => {
    console.log('onChange', this.reasonList, ids);
    this.reasonList = this.reasonList.map((el) => {
      const _index = ids.findIndex((item) => item === el.dictValue);
      if (_index !== -1) {
        el.checked = !el.checked;
      }
      return el;
    });
    // this.reasonList = list;
  };

  @action public onValChange = (type: string, val: string) => {
    this[type] = val;
    if (type === 'isReal') {
      if (val === '0') {
        this.getList();
        this.showReasonList = true;
      } else {
        this.showReasonList = false;
      }
    }
  };

  @action public onSubmit = async () => {
    if (!this.interviewed) {
      message.error('是否已面试不能为空');
      return;
    }
    if (this.interviewed === '1' && !this.isReal) {
      message.error('岗位内容是否真实不能为空');
      return;
    }
    const positionNotTrueReason = [];
    for (let i = 0; i < this.reasonList.length; i++) {
      const element = this.reasonList[i];
      if (element.checked) {
        positionNotTrueReason.push(element.dictValue);
      }
    }
    try {
      this.loading = true;
      const res = await request<BaseData<string>>({
        url: '/pbb/platform/sys/recruit/userInterviewFeedback',
        method: 'POST',
        data: {
          recruitId: this.item.recruitId,
          positionIsTrue: this.isReal,
          interviewed: this.interviewed,
          interviewEvaluate: this.evaluate,
          positionNotTrueReason,
        },
      });
      message.success(res.data);
      this.cb();
      this.onClose();
    } finally {
      this.loading = false;
    }
  };

  @action public onClose = () => {
    this.onCancel();
    this.interviewed = '';
    this.isReal = '';
    this.evaluate = '';
    this.reasonList = [];
  };
}

const AnchorEvaluate = ({ visible, onCancel, item, cb, type }: ModalProps) => {
  const store = useMemo(() => new Store(), []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!visible) {
      return;
    }
    store.cb = cb;
    store.item = item;
    store.onCancel = onCancel;
    if (type === 'edit') {
      store.getInfo();
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      title={'主播评价'}
      centered={true}
      onCancel={store.onClose}
      confirmLoading={store.loading}
      onOk={store.onSubmit}
      maskClosable={false}
    >
      <div className="flex">
        <div className="text-14 font-500">
          <span className="text-[red]">*</span>是否到面：
        </div>
        <Radio.Group
          value={store.interviewed}
          onChange={(e) => store.onValChange('interviewed', e.target.value)}
        >
          <Radio value={'1'}>是</Radio>
          <Radio value={'0'}>否</Radio>
        </Radio.Group>
      </div>
      {store.interviewed === '1' && (
        <div className="flex mt-3">
          <div className="text-14 font-500">
            <span className="text-[red]">*</span>岗位内容：
          </div>
          <Radio.Group
            value={store.isReal}
            onChange={(e) => store.onValChange('isReal', e.target.value)}
          >
            <Radio value={'1'}>真实</Radio>
            <Radio value={'0'}>不真实</Radio>
          </Radio.Group>
        </div>
      )}
      {store.showReasonList && (
        <div className="flex px-5 flex-wrap">
          {store.reasonList?.map((el) => {
            return (
              <div
                key={el.dictValue}
                className={'text-heading text-14 px-4 py-3 mt-3 mr-3 relative'}
                style={{ background: el.checked ? '#E7F4FD' : '#F8FAFB' }}
                onClick={() => store.onChange([el.dictValue])}
              >
                {el.dictName}
              </div>
            );
          })}
        </div>
      )}
      <div className="pb-3 mt-3">
        <div className="text-14 font-500 ml-1.5">面试评价：</div>
        <Input.TextArea
          showCount={true}
          className="rounded-1 w-full px-3 !h-30 !mt-2"
          placeholder="请输入其他原因"
          value={store.evaluate}
          maxLength={50}
          onChange={(e) => store.onValChange('evaluate', e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default observer(AnchorEvaluate);
