import AnchorInfoModel from '@/compnents/anchorInfo/model';
import {
  type IMainSubStructureModel,
  type NormalProgrammeParams,
  type PaginationData,
  RenderByPermission,
  SearchListModal,
  copyToClipboard,
  getWxUrl,
  request,
  requireSource,
} from '@/utils';
import { Button } from 'antd';
import { action, observable } from 'mobx';
import type { ListItem } from './interface';
import { DATA } from './mock';

const STATUS_DICT = [
  {
    label: '待雇主邀约',
    value: '1',
  },
  {
    label: '雇主拒绝邀约',
    value: '2',
  },
  {
    label: '待主播报名',
    value: '3',
  },
  {
    label: '主播拒绝报名',
    value: '4',
  },
  {
    label: '待发起面试',
    value: '5',
  },
  {
    label: '待面试',
    value: '6',
  },
  {
    label: '面试取消',
    value: '7',
  },
  {
    label: '待评价',
    value: '8',
  },
  {
    label: '待雇主评价',
    value: '9',
  },
  {
    label: '待主播评价',
    value: '10',
  },
  {
    label: '未到面',
    value: '11',
  },
  {
    label: '面试结束',
    value: '12',
  },
];

const JOBTYPE_DICT = [
  {
    label: '全职',
    value: '1',
  },
  {
    label: '兼职',
    value: '2',
  },
  {
    label: '临时兼职',
    value: '3',
  },
];

export default class Store {
  constructor() {
    this.mainStore.grid.onQuery();
  }
  @observable public historyVisible = false;

  @observable public historyData: ListItem;

  @observable public refuseVisible = false;

  @observable public refuseData: ListItem;

  @observable public confirmVisible = false;

  @observable public confirmData: ListItem;

  @observable public interviewType: 'new' | 'edit' = 'new'; // new 发起面试，edit 编辑面试

  @observable public interviewVisible = false;

  @observable public interviewData: ListItem;

  @observable public cancelVisible = false;

  @observable public cancelData: ListItem;

  @observable public anchorEvaVisible = false;

  @observable public anchorEvaData: ListItem;

  @observable public anchorEvaType: 'new' | 'edit' = 'new'; // new 主播评价，edit 查看主播评价

  @observable public employerEvaVisible = false;

  @observable public employerEvaData: ListItem;

  @observable public employerEvaType: 'new' | 'edit' = 'new'; // new 雇主评价，edit 查看雇主评价

  @observable public applyPaymentVisible = false;

  @observable public applyPaymentData: ListItem;

  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框

  @action public openHistory = (item: ListItem) => {
    this.historyData = item;
    this.historyVisible = true;
  };

  @action public openRefuse = (item: ListItem) => {
    this.refuseData = item;
    this.refuseVisible = true;
  };

  @action public openConfirm = (item: ListItem) => {
    this.confirmData = item;
    this.confirmVisible = true;
  };

  @action public openInterview = (type: 'new' | 'edit', item?: any) => {
    this.interviewData = item;
    this.interviewType = type;
    this.interviewVisible = true;
  };

  @observable openCancel = (item: ListItem) => {
    this.cancelData = item;
    this.cancelVisible = true;
  };

  @action public openAnchorEva = (type: 'new' | 'edit', item: ListItem) => {
    this.anchorEvaType = type;
    this.anchorEvaData = item;
    this.anchorEvaVisible = true;
  };

  @action public openEmployerEva = (type: 'new' | 'edit', item: ListItem) => {
    this.employerEvaType = type;
    this.employerEvaData = item;
    this.employerEvaVisible = true;
  };

  @action public openApplyPayment = (item: ListItem) => {
    this.applyPaymentData = item;
    this.applyPaymentVisible = true;
  };

  @action public refreshData = () => {
    this.mainStore.grid.gridModel.onRefresh();
  };

  @action public onShare = async (params, text) => {
    const url = await getWxUrl(params);
    // const copyText = text + url;
    copyToClipboard(text + url);
  };

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'select',
        field: 'jobType',
        label: '职位类型',
        data: JOBTYPE_DICT,
      },

      {
        type: 'input',
        field: 'positionName',
        label: '职位名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'companyName',
        label: '企业名称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'userName',
        label: '主播姓名',
        placeholder: '请输入',
      },
      {
        type: 'select',
        field: 'status',
        label: '状态',
        data: STATUS_DICT,
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [],
    grid: {
      rowHeight: 80,
      columns: [
        {
          key: 'opreations',
          name: '操作',
          frozen: true,
          width: 240,
          formatter: ({ row }) => {
            // const pageId = 53;
            return (
              <div className="flex flex-wrap items-center w-full h-full">
                <RenderByPermission permissionId={'53_30'}>
                  <Button
                    type="link"
                    onClick={() => this.openHistory(row)}
                  >
                    历史进度
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={'53_31'}>
                  {(row.status === 1 || row.status === 3) && (
                    <Button
                      type="link"
                      onClick={() => {
                        window.top.RingPermission.openTab(
                          `/pbb-pc-management/positionManagement/index?positionId=${row.positionId}&isShowDetail=${true}`,
                          '76',
                          '职位管理',
                        );
                      }}
                    >
                      查看JD
                    </Button>
                  )}
                </RenderByPermission>
                <RenderByPermission permissionId={'53_40'}>
                  {row.status === 1 && (
                    <>
                      <Button
                        type="link"
                        onClick={() =>
                          this.onShare({ channel: 6, anchorId: row.userId }, '你的管家给你推荐了主播，快来看看~')
                        }
                      >
                        分享主播
                      </Button>
                      <Button
                        type="link"
                        onClick={() => this.openRefuse(row)}
                      >
                        拒绝邀约
                      </Button>
                    </>
                  )}
                </RenderByPermission>
                {row.status === 3 && (
                  <>
                    <RenderByPermission permissionId={'53_32'}>
                      <Button
                        type="link"
                        onClick={() =>
                          this.onShare(
                            { channel: 1, positionId: row.positionId },
                            '你的经纪人给你推荐了职位，快来看看~',
                          )
                        }
                      >
                        分享JD
                      </Button>
                    </RenderByPermission>
                    {/* <RenderByPermission permissionId={'53_33'}>
                      <Button
                        type="link"
                        onClick={() => this.openConfirm(row)}
                      >
                        报名确认
                      </Button>
                    </RenderByPermission> */}
                  </>
                )}
                {row.status === 5 && (
                  <RenderByPermission permissionId={'53_34'}>
                    <Button
                      type="link"
                      onClick={() => this.openInterview('new', row)}
                    >
                      发起面试
                    </Button>
                  </RenderByPermission>
                )}
                {(row.status === 6 || row.status === 7) && (
                  <RenderByPermission permissionId={'53_35'}>
                    <Button
                      type="link"
                      onClick={() => this.openInterview('edit', row)}
                    >
                      修改面试日程
                    </Button>
                  </RenderByPermission>
                )}
                {row.status === 6 && (
                  <RenderByPermission permissionId={'53_36'}>
                    <Button
                      type="link"
                      onClick={() => this.openCancel(row)}
                    >
                      取消面试
                    </Button>
                  </RenderByPermission>
                )}
                {(row.status === 9 || row.status === 12) && (
                  <RenderByPermission permissionId={'53_38'}>
                    <Button
                      type="link"
                      onClick={() => {
                        this.openAnchorEva('edit', row);
                      }}
                    >
                      查看主播评价
                    </Button>
                  </RenderByPermission>
                )}
                {(row.status === 8 || row.status === 10) && (
                  <RenderByPermission permissionId={'53_37'}>
                    <Button
                      type="link"
                      onClick={() => {
                        this.openAnchorEva('new', row);
                      }}
                    >
                      主播评价
                    </Button>
                  </RenderByPermission>
                )}
                {(row.status === 8 || row.status === 9) && (
                  <RenderByPermission permissionId={'53_42'}>
                    <Button
                      type="link"
                      onClick={() => {
                        this.openEmployerEva('new', row);
                      }}
                    >
                      雇主评价
                    </Button>
                  </RenderByPermission>
                )}
                {(row.status === 10 || row.status === 12) && (
                  <RenderByPermission permissionId={'53_43'}>
                    <Button
                      type="link"
                      onClick={() => {
                        this.openEmployerEva('edit', row);
                      }}
                    >
                      查看雇主评价
                    </Button>
                  </RenderByPermission>
                )}
                {row.isUserFeedback === 1 && (
                  <RenderByPermission permissionId={'53_39'}>
                    <Button
                      type="link"
                      onClick={() => this.openApplyPayment(row)}
                    >
                      发红包
                    </Button>
                  </RenderByPermission>
                )}
              </div>
            );
          },
        },
        {
          key: 'updateTime',
          name: '更新时间',
          width: 200,
        },
        {
          key: 'userName',
          name: '主播姓名',
          formatter: ({ row }) => {
            return (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => {
                  //弹出主播信息资料
                  this.anchorInfoModalStore.onShow(row.userId);
                }}
              >
                <div>{row.userName}</div>
                <img
                  src={requireSource('/common/idcard.png')}
                  alt={''}
                  className={'w-4 h-4 ml-1'}
                />
              </div>
            );
          },
        },
        {
          key: 'jobType',
          name: '职位类型',
          formatter: ({ row }) => {
            const item = JOBTYPE_DICT.find((item) => Number(item.value) === row.jobType);
            return item?.label;
          },
        },
        {
          key: 'positionName',
          name: '职位名称',
          width: 300,
        },
        {
          key: 'employerName',
          name: '雇主姓名',
          width: 150,
        },
        {
          key: 'companyName',
          name: '公司名称',
          width: 300,
        },
        {
          key: 'brokerName',
          name: '经纪人',
          width: 150,
        },
        {
          key: 'butlerName',
          name: '管家',
          width: 150,
        },
        {
          key: 'status',
          name: '状态',
          formatter: ({ row }) => {
            // return row.jobType === 1 ? '全职' : '兼职';
            const item = STATUS_DICT.find((item) => Number(item.value) === row.status);
            return item?.label;
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'recruitId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        console.log('onquery', [params]);
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        console.log(postParams);
        return request<PaginationData<ListItem>>({
          url: '/pbb/platform/sys/recruit/getRecruitPage',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
